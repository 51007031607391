/**
 * 
 * @param callback 
 * @param timeout 
 */
export const throttle = (callback, timeoutMillis = 100): () => void => {
  let waiting: boolean = false;

  return (): void => {
    if (!waiting) {
      callback();
      waiting = true;

      window.setTimeout((): void => {
        callback();
        waiting = false;
      }, timeoutMillis);
    }
  };
};

interface ISearchParameters {
  [key: string]: string;
}

/**
 * 
 * @returns 
 */
export const parseParameters = (): ISearchParameters => {
  const parameters: ISearchParameters = {};

  const queries: string[] = document.location.search.substring(1).split("&");
  for (const query of queries) {
    if (query === "") {
      continue;
    }

    let pair: string[] = query.split("=");

    if (pair.length === 1) {
      pair.push("");
    } else if (pair.length > 2) {
      const slicedPair: string[] = pair.slice(2);
      const pairCopy: string[] = [pair[0], pair[1]];

      for (const value of slicedPair) {
        pairCopy[1] += `=${value}`;
      }
      
      pair = pairCopy;
    }

    parameters[pair[0]] = pair[1];
  }

  return parameters;
};

/**
 * 
 * @returns 
 */
export const formatCreatedDate = (date: string | Date): string => {
  if (typeof date === "string") {
    date = new Date(date);
  }

  return date.toLocaleDateString("default", {
    month: "long",
    day: "numeric",
    year: "numeric"
  });
};

/**
 * 
 * @param text 
 * @param count 
 * @returns 
 */
export const plural = (text: string, count: number): string => {
  return count === 1 ? text : `${text}s`;
};

// From: https://medium.com/@mhagemann/the-ultimate-way-to-slugify-a-url-string-in-javascript-b8e4a0d849e1
export const slugify = string => {
  const a = "àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœṕŕßśșțùúüûǘẃẍÿź·/_,:;";
  const b = "aaaaaaaaceeeeghiiiimnnnoooooprssstuuuuuwxyz------";
  const p = new RegExp(a.split("").join("|"), "g");
  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with ‘and’
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};