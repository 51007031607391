import React, { useEffect, useState } from "react";
import { throttle } from "../../includes/utils";
import ArrowUpSVG from "../../global/assets/arrow-up.svg";
import "./BackToTop.scss";

const BackToTop = (): JSX.Element => {
  const [isShown, setIsShown] = useState(false);
  const [alternateColor, setAlternateColor] = useState(false);

  /**
   * 
   */
  const scrollToTop = (): void => {
    window.location.hash = "";
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    const handler = throttle((): void => setIsShown(window.scrollY !== 0));
    window.addEventListener("scroll", handler);

    return (): void => window.removeEventListener("scroll", handler);
  }, []);

  useEffect(() => {
    const footer: Element | null = document.querySelector(".GlobalFooter");
    if (!footer) {
      return;
    }

    const observer: IntersectionObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]): void => {
      setAlternateColor(entries[0].isIntersecting);
    }, { rootMargin: "0px 0px -45px 0px" });
    observer.observe(footer);

    return (): void => observer.disconnect();
  }, []);

  const classList: string = `BackToTop${isShown ? " BackToTop--shown" : ""}${alternateColor ? " BackToTop--light" : ""}`;
  return (
    <button className={classList} onClick={scrollToTop} aria-label="Back to top">
      <ArrowUpSVG/>
    </button>
  );
};
export default BackToTop;