import React, { useEffect } from "react";
import { globalHistory } from "@reach/router";
import { Helmet } from "react-helmet";
import GlobalNav from "../global/GlobalNav";
import GlobalFooter from "../global/GlobalFooter";
import BackToTop from "./BackToTop";
import "../../global/scss/global.scss";
import "./GlobalLayout.scss";

interface IGlobalLayoutProps {
  siteMetadata: {
    name: string;
    description: string;
  }
  children: JSX.Element | JSX.Element[];
  navShadowHidden?: boolean;
  smoothScroll?: boolean;
}

const Index = ({ siteMetadata, children, navShadowHidden = false, smoothScroll = false }: IGlobalLayoutProps): JSX.Element => {
  if (smoothScroll) {
    useEffect(() => {
      const currentPath = window.location.pathname;

      const cancelListener = globalHistory.listen(({ location }): void => {
        if (location.pathname !== currentPath) {
          document.documentElement.classList.remove("smooth-scroll");
        }
      });
      window.setTimeout((): void => document.documentElement.classList.add("smooth-scroll"));

      return (): void => {
        cancelListener();
        document.documentElement.classList.remove("smooth-scroll");
      };
    }, []);
  }
  
  return <>
    <Helmet htmlAttributes={{ lang: "en" }}>
      <title>{siteMetadata.name}</title>
      <meta name="description" content={siteMetadata.description}/>
    </Helmet>
    <div className="GlobalLayout">
      <GlobalNav hideShadow={navShadowHidden}/>
      {children}
      <GlobalFooter/>
      <BackToTop/>
    </div>
  </>;
};
export default Index;