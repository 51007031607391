import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "./GlobalFooter.scss";

const GlobalFooter = (): JSX.Element => {
  return (
    <footer className="GlobalFooter">
      <div className="GlobalFooter__content">
        <div className="GlobalFooter__contentLeft">
          <figure>
            <a href="https://www.crometrics.com">
              <StaticImage src="../../global/assets/cro-logo.png" alt="Cro Metrics"/>
            </a>
            <figcaption>Experiment-led Marketing That Accelerates Revenue</figcaption>
          </figure>
          <div className="GlobalFooter__links">
            <a href="https://www.crometrics.com/contact">Request a Free Consultation</a>
            <a href="https://www.crometrics.com/subscribe">Subscribe</a>
          </div>
          <div className="GlobalFooter__copyrightAndPrivacy">
            <div>Cro Metrics © 2022</div>
            <a href="https://www.crometrics.com/privacy-policy">Privacy Policy</a>
          </div>
        </div>
        <div className="GlobalFooter__contentRight">
          <a href="https://www.google.com/partners/agency?id=8034082510">
            <StaticImage src="../../global/assets/footer/google-partner.png" alt="Google Partner"/>
          </a>
          <StaticImage src="../../global/assets/footer/meta-icon.png" alt="Meta Business Partner"/>
          <StaticImage src="../../global/assets/footer/amazon-logo.png" alt="Amazon Ads"/>
        </div>
      </div>
    </footer>
  );
};
export default GlobalFooter;