import { Link, navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { throttle } from "../../includes/utils";
import SupportLogo from "../../global/assets/support-logo.svg";
import ArrowUpSVG from "../../global/assets/arrow-up.svg";
import "./GlobalNav.scss";

interface IGlobalNavProps {
  hideShadow?: boolean;
}

const GlobalNav = ({ hideShadow }: IGlobalNavProps): JSX.Element => {
  const [showShadow, setShowShadow] = useState(!hideShadow);

  if (hideShadow) {
    useEffect(() => {
      const handler = throttle((): void => setShowShadow(window.scrollY !== 0));
      window.addEventListener("scroll", handler);
      return (): void => window.removeEventListener("scroll", handler);
    }, []);
  }

  const search = (event): void => {
    if (event.key === "Enter" || event.code === "Enter") {
      navigate(`/search?q=${encodeURIComponent(event.target.value)}`);
      event.target.blur();
    }
  };
  
  const classList: string = `GlobalNav${showShadow ? " GlobalNav--shadow" : ""}`;
  return (
    <nav className={classList}>
      <div className="GlobalNav__content">
        <Link to="/" className="GlobalNav__logo">
          <SupportLogo/>
        </Link>
        <div className="GlobalNav__contentLeft">
          <Link to="/">Home</Link>
          <Link to="/general">General</Link>
          <button className="GlobalNav__dropdown">
            <div className="GlobalNav__dropdownTitle">
              Programs
              <ArrowUpSVG/>
            </div>
            <ul className="GlobalNav__dropdownContent">
              <li>
                <Link to="/growth-strategy">Growth Strategy</Link>
              </li>
              <li>
                <Link to="/program-management">Program Management</Link>
              </li>
            </ul>
          </button>
          <button className="GlobalNav__dropdown">
            <div className="GlobalNav__dropdownTitle">
              Delivery
              <ArrowUpSVG/>
            </div>
            <ul className="GlobalNav__dropdownContent">
              <li>
                <Link to="/engineering">Engineering</Link>
              </li>
              <li>
                <Link to="/quality-assurance">Quality Assurance</Link>
              </li>
            </ul>
          </button>
        </div>
        <div className="GlobalNav__contentRight">
          <input type="search" placeholder="Search for a topic..." onKeyDown={search}/>
          <a href="https://my.crometrics.com" target="_blank" rel="noreferrer">Client Login</a>
        </div>
      </div>
    </nav>
  );
};
export default GlobalNav;